<template>
  <section class="top">
    <div class="top__container container top__view--desktop">
      <div class="top__box">
        <h1 class="top__title">{{ data.title }}</h1>
        <p class="top__text" v-html="data.description1"></p>
      </div>
      <div class="top__box">
        <div class="top__image-box">
          <img :src="data.image1.components[0]" :alt="data.image1.alt" class="top__image-1" width="267">
          <img :src="data.image1.components[1]" :alt="data.image1.alt" class="top__image-2" width="190">
          <img :src="data.image1.components[2]" :alt="data.image1.alt" class="top__image-3" width="132">
        </div>
        <p class="top__text" v-html="data.description2"></p>
        <img :src="data.image2.src" :alt="data.image2.alt" class="top__second-image" width="225">
      </div>
    </div>
    <div class="top__container container top__view--mobile">
      <h1 class="top__title">{{ data.title }}</h1>
      <p class="top__text" v-html="data.description2"></p>
      <img :src="data.image2.src" :alt="data.image2.alt" class="top__second-image" width="225">
      <p class="top__text" v-html="data.description1"></p>
      <img :src="data.image1.srcMobile" :alt="data.image1.alt" class="top__main-image" width="357">
    </div>
    <div class="top__bg-box">
      <img :src="data.bg.src" :alt="data.bg.alt" class="top__bg">
      <div class="container">
        <picture>
          <source media="(max-width: 1200px)" :srcset="data.bgText.srcMobile">
          <img :src="data.bgText.src" :alt="data.bgText.alt" class="top__bg-text">
        </picture>
      </div>
    </div>
    <div class="container">
      <h2 class="top__subtitle">
        <img :src="require('@/assets/icons/text-star.svg')" alt="*" class="top__subtitle-icon">
        {{ data.subTitle }}
      </h2>
      <ul class="top__list">
        <li class="top__item" v-for="(item, index) in data.advantages" :key="index">
          <h3 class="top__item-title">{{ item.title }}</h3>
          <p class="top__item-description">{{ item.description }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.TOP
    }
  }
}
</script>

<style lang="less">
  .top {
    padding-top: 104px;
    padding-bottom: 35px;

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__image-box {
      position: relative;
      width: 357px;
      height: 243px;

      & .top__image {
        &-1,
        &-2 {
          animation: wiggle-1 3s ease-in-out 0s infinite;
          animation-play-state: paused;
        }
        &-1 {
          position: absolute;
          top: 5px;
          left: 0px;
        }
        &-2 {
          position: absolute;
          top: 50px;
          right: 1px;
          animation-name: wiggle-2;
        }
        &-3 {
          position: absolute;
          top: 165px;
          left: 0;
        }
      }

      &:hover {
        & .top__image {
          &-1,
          &-2 {
            animation-play-state: running;
          }
        }
      }

      @keyframes wiggle-1 {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(10deg);
        }
        100% {
          transform: rotate(0);
        }
      }
      @keyframes wiggle-2 {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(-10deg);
        }
        100% {
          transform: rotate(0);
        }
      }
    }

    &__box {
      width: 100%;
      padding-bottom: 98px;

      &:first-child {
        max-width: 560px;
      }

      &:last-child {
        display: flex;
        flex-direction: column;

        max-width: 357px;

        & .top {
          &__text {
            margin-bottom: 40px;
            padding-left: 3px;
          }
        }
      }
    }

    &__title {
      margin: 0;
      font-weight: bold;
      font-size: 70px;
      line-height: 120%;
      letter-spacing: -0.01em;

      margin-bottom: 40px;
    }

    &__text {
      margin: 0;
      line-height: 130%;
      color: var(--color-black);
    }

    &__main-image,
    &__image-box {
      margin-bottom: auto;
      padding-top: 5px;
    }

    &__second-image {
      width: 225px;
      height: auto;
      margin-left: -5px;
      margin-bottom: -7px;
    }

    &__view {
      &--mobile {
        display: none;
      }
    }

    &__bg {
      position: absolute;
      object-fit: cover;

      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;

      &-box {
        position: relative;
        width: 100%;
        height: 500px;

        overflow: hidden;

        margin-bottom: 35px;

        & .container {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }

      &-text {
        display: block;
        position: relative;
        z-index: 2;

        width: 100%;
        max-width: 1440px;
      }
    }

    &__subtitle {
      margin: 0;
      text-align: right;
      display: flex;
      align-items: flex-end;
      width: 100%;

      color: var(--color-gray-medium);
      font-size: 14px;
      line-height: 110%;
      letter-spacing: 0.145em;

      margin-bottom: 35px;

      &-icon {
        margin-right: 17px;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
    }

    &__item {
      width: 30%;
      max-width: 251px;

      box-sizing: border-box;

      &-title {
        margin: 0;
        position: relative;

        font-weight: bold;
        font-size: 70px;
        line-height: 110%;
        letter-spacing: -0.01em;

        padding-bottom: 14px;
        margin-bottom: 20px;

        &::before {
          content: "";
          position: absolute;

          width: 100%;
          height: 4px;
          left: 0;
          bottom: 0;

          border-radius: 4px;
        }
      }

      &:nth-child(1n) {
        & .top__item-title::before {
          background: linear-gradient(131.95deg, #BD79DC -6.25%, #8D95E1 108.26%);
        }
      }

      &:nth-child(2n) {
        & .top__item-title::before {
          background: linear-gradient(180deg, #FE8E4C 0%, #FF5D5B 95.8%);
        }
      }

      &:nth-child(3n) {
        & .top__item-title::before {
          background: linear-gradient(270deg, #84DE89 0%, #58C287 100%);
        }
      }

      &-description {
        margin: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    .top {
      &__bg-box {
        & .container {
          justify-content: flex-start;
          padding-bottom: 14px;
        }
      }

      &__bg-text {
        width: 100%;
        max-width: 345px;
      }
    }
  }

  @media (max-width: 980px) {
    .top {
      &__title {
        font-size: 60px;
      }
    }
  }

  @media (max-width: 890px) {
    .top {
      &__bg-box {
        & .container {
          justify-content: flex-start;
          padding-bottom: 0;
        }
      }

      &__title {
        font-size: 40px;
      }

      &__item {
        &-title {
          font-size: 50px;
        }
      }
    }
  }

  @media (max-width: 750px) {
    .top {
      padding-top: 36px;
      padding-bottom: 35px;

      &__container {
        position: relative;
        padding-bottom: 176px;
      }

      &__view {
        &--desktop {
          display: none;
        }

        &--mobile {
          display: block;
        }
      }

      &__title {
        margin-bottom: 50px;
      }

      &__text {
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }

      &__main-image {
        position: absolute;
        z-index: 2;
        bottom: -120px;
        left: 50%;
        transform: translateX(-50%);

        width: 100%;
        max-width: 357px;
      }

      &__second-image {
        margin-left: -8px;
        margin-bottom: 5px;
      }

      &__bg-box {
        & .container {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

    @media (max-width: 580px) {
    .top {

      & .container {
        position: relative;
      }

      &__list {
        flex-direction: column;
      }

      &__item {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        &-description {
          font-size: 18px;
        }
      }

      &__subtitle {
        margin: 0;
        justify-content: flex-start;
        position: absolute;

        right: calc(-100vw + 13px);
        top: 0;
        
        transform: rotate(90deg);
        transform-origin: top left;
      }
    }
  }
</style>