const state = {
  typeOfWork: {
    title: "Виды работ для типов заказчиков",
    tabs: [
      {
        title: "Startup и малому бизнесу",
        list: [
          {
            title: "Запустить MVP для startup за 30 дней (app, web):",
            list: [
              {
                icon: require("@/assets/icons/cube.svg"),
                description: "Мобильная и web разработка полного цикла (маркетплейсы, личные кабинеты, app для сотрудников, AR-приложения, система лояльности, чаты и мессенджеры)"
              },
              {
                icon: require("@/assets/icons/teacher.svg"),
                description: "Подключаем готовые микросервисы (экономия времени и проверенное качество)"
              },
              {
                icon: require("@/assets/icons/google-play.svg"),
                description: "От идеи до релиза в сторах (App Store, Google Play): регистрация сразу со старта проекта"
              },
            ]
          },
          {
            title: "Разработка web-сайта / e-commerce от 10 дней:",
            list: [
              {
                icon: require("@/assets/icons/random.svg"),
                description: "С 0 или с интеграцией в действующие решения вашей компании"
              },
              {
                icon: require("@/assets/icons/bullseye.svg"),
                description: "Полный цикл: от доработки ТЗ и копирайтинга, до вёрстки и тестирования"
              },
              {
                icon: require("@/assets/icons/rocket.svg"),
                description: "Есть fast track: запуск серий лендингов на разные ЦА для тестирования гипотез (разработка креативов, тестирование каналов digital marketing)"
              },
            ]
          },
          {
            title: "Консалтинг и «упаковка» для startup:",
            list: [
              {
                icon: require("@/assets/icons/clipboard-list.svg"),
                description: `
                  Cust Dev (подтверждение бизнес-модели и новые инсайты):
                  <ul>
                    <li>
                      <strong>качественный этап (проверка и поиск гипотез):</strong>
                      10-20 интервью с пользователями, гипотеза ядра аудитории, 3-5 интервью с экспертами (ФРИИ, Сколково, etc.)
                    </li>
                    <li>
                      <strong>количественный этап:</strong>
                      (проверка гипотез и unit-экономики проекта): опросы от 500 чел., тестирование спроса через лэндинги
                    </li>
                  </ul>
                `
              },
              {
                icon: require("@/assets/icons/briefcase.svg"),
                description: `
                  Юридическая обвязка для РФ / Global:
                  </br>
                  </br>
                  <ul>
                    <li>
                      регистрационные документы (учр. документы, корп. договор, инвестиционный договор)
                    </li>
                    <li>
                      регистрация интеллектуальной собственности и патентование
                    </li>
                    <li>
                      NDA и контракты с ключевыми сотрудниками (снятие рисков)
                    </li>
                    <li>
                      привлечение безвозмездного финансирования (Preon.Tech партнер <a href="/">«Фонда Содействия Инновациям»</a>)
                    </li>
                  </ul>
                `
              },
              {
                icon: require("@/assets/icons/filter.svg"),
                description: `
                  Воронка продаж + unit-экономика:
                  </br>
                  </br>
                  <ul>
                    <li>
                      воронка продаж и TOP-каналы сбора лидов (по итогам тестов)
                    </li>
                    <li>
                      unit-экономика + p&l проекта с прогнозом стоимости
                    </li>
                    <li>
                      ТЗ dash board для web аналитик (опция настройки на бою)
                    </li>
                  </ul>
                `
              },
              {
                icon: require("@/assets/icons/search.svg"),
                description: `
                  Аудит конкурентов в РФ / Global (проверка уникальности на разных рынках):
                  <ul>
                    <li>
                      уникальные атрибуты проекта
                    </li>
                    <li>
                      референсы на разных рынках (сколько собрали инвестиций, сильные стороны, etc.)
                    </li>
                    <li>
                      возможные внешние рынки для выхода и оценка емкости рынка
                    </li>
                  </ul>
                `
              },
              {
                link: {
                  href: "",
                  text: "Примеры startup, которые ведет Preon.Tech",
                  color: "#FFF6E8"
                },
              },
              {
                link: {
                  href: "",
                  text: "Документ с прайсом работ",
                  color: "#FAD8FF"
                },
              },
            ]
          },
        ]
      },
      {
        title: "Крупным компаниям",
        list: [
          {
            title: "Разработать высоконагруженное ПО с внедрением в ИТ-архитектуру вашей компании:",
            list: [
              {
                icon: require("@/assets/icons/file-code.svg"),
                description: `
                  Примеры:
                  <ul>
                    <li>
                      моб. приложения и web
                    </li>
                    <li>
                      RPA и ML (reinforcement learning)
                    </li>
                    <li>
                      oCRM / aCRM
                    </li>
                    <li>
                      бонусный процессинг и системы целевого маркетинга
                    </li>
                    <li>
                      Omni-Сhannel Platform и Trouble Ticket Solution (для CX)
                    </li>
                  </ul>
                `
              },
              {
                icon: require("@/assets/icons/cubes.svg"),
                description: `
                  Виды работ:
                  <ul>
                    <li>
                      аудит, проектирование и реинжиниринг IT-систем
                    </li>
                    <li>
                      разработка на front-end (React, Angular, extJS) и back-end (Java, Python, R, C++, SQL, NoSQL, Postgres): oCRM / aCRM, ERP
                    </li>
                    <li>
                      построение микросервисной архитектуры и разработка высоконагруженных приложений
                    </li>
                  </ul> 
                `
              },
            ]
          },
          {
            title: "Аутстаффинг и сбор IT-команд от 7 дней:",
            list: [
              {
                icon: require("@/assets/icons/multipassport.svg"),
                description: "готовая база IT-разработчиков</br> по РФ, 5 разных резюме</br> на 1 позицию"
              },
              {
                icon: require("@/assets/icons/comments.svg"),
                description: "отработанные процессы</br> собеседований"
              },
              {
                icon: require("@/assets/icons/award.svg"),
                description: "гарантия качества: бесплатно заменим сотрудника без потери сроков разработки"
              },
            ]
          },
          {
            title: "Бесплатный аудит (ИТ, бизнес):",
            list: [
              {
                icon: require("@/assets/icons/chart-bar.svg"),
                description: `формируем рекомендации и прогноз экономики (↓ OPEX и ↑ конверсии)`
              },
              {
                icon: require("@/assets/icons/chart-line.svg"),
                description: "эффект от реализации проекта > расходов на разработку"
              },
              {
                icon: require("@/assets/icons/chart-pie.svg"),
                description: "↓ time to market на >30% даже при текущей команде"
              },
            ]
          },
          {
            title: "Machine learning и digital-трансформация бизнес-процессов:",
            list: [
              {
                icon: require("@/assets/icons/code.svg"),
                description: "построение полной</br> ИТ-архитектуры"
              },
              {
                icon: require("@/assets/icons/puzzle-piece.svg"),
                description: "автоматизация и роботизация процессов, выстраивание системы отчетности в real-time"
              },
              {
                icon: require("@/assets/icons/handshake.svg"),
                description: "бизнес-консалтинг (генерация идей автоматизации)"
              },
            ]
          },
          {
            title: "Найти точки роста за счет автоматизации: экономия OPEX, рост доходов (бизнес-консалтинг)",
            list: [
              {
                link: {
                  href: "",
                  text: "Примеры крупных компаний, которые ведет Preon.Tech",
                  color: "#EEF3FD"
                },
              },
              {
                link: {
                  href: "",
                  text: "Документ с прайсом работ",
                  color: "#CEFFC9"
                },
              },
            ]
          },
        ]
      }
    ]
  },
}

const getters = {
  TYPE_OF_WORK: state => state.typeOfWork,
}

export default {
state,
getters,
}