const state = {
  evaluation: {
    title: "Оставьте запрос на оценку",
    description: "Мы оперативно свяжемся с вами, уточним задачу и отправим предложение</br> в течение <strong>1 дня</strong>",
    picture: {
      src: require("@/assets/images/evaluation-picture.png"),
      alt: "Оставьте запрос на оценку"
    },
    bg: {
      src: require("@/assets/images/evaluation-bg.jpg"),
      alt: "PREON TECH"
    },
    bgText: {
      src: require("@/assets/images/top-bg-text-desktop.png"),
      srcMobile: require("@/assets/images/top-bg-text-mobile.png"),
      alt: "PREON TECH"
    },
  },
}

const getters = {
  EVALUATION: state => state.evaluation,
}

export default {
  state,
  getters,
}