<template>
  <section class="evaluation">
    <div class="evaluation__container container">
      <div class="evaluation__content grid grid--2-columns grid--middle">
        <div class="evaluation__box">
          <img :src="require('@/assets/images/request-evaluation-second.png')" :alt="data.picture.alt" width="850" class="evaluation__picture">
        </div>
        <div class="evaluation__box">
          <h2 class="evaluation__title">{{ data.title }}</h2>
          <p class="evaluation__description" v-html="data.description"></p>
          <send-request-button></send-request-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SendRequestButton from './SendRequestButton.vue'
export default {
  components: { SendRequestButton },
  computed: {
    data() {
      return this.$store.getters.EVALUATION
    }
  }
}
</script>

<style lang="less" scoped>
  .evaluation {
    padding: 290px 0;
    padding-bottom: 220px;

    &__box {
      position: relative;
    }

    &__picture {
      position: absolute;
      top: -260px;
      left: -150px;

      min-width: 850px;
    }
  }

  @media (max-width: 1044px) {
    .evaluation {
      &__picture {
        min-width: 650px;
        top: -200px;
        left: -100px;
      }
    }
  }

  @media (max-width: 890px) {
    .evaluation {
      padding: 70px 0;
      padding-bottom: 30px;

      &__picture {
        display: block;
        position: relative;
        left: auto;
        top: auto;
        min-width: auto;
        width: 100%;
        margin: 0;
      }

      &__box {
        &:first-child {
          display: flex;
          justify-content: center;
          order: 2;
          padding: 0;

          margin-top: 30px;
        }
      }

      &__content {
        grid-template-columns: 1fr;
      }
    }
  }

  @media (max-width: 570px) {
    .evaluation {
      &__title {
        padding-right: 60px;
      }
      &__picture {
        width: 150%;
        margin-right: -20px;
      }
    }
  }
</style>