const state = {
  request: {
    title: "Отправка запроса",
    step: 0,
    maxFileSizeInMb: 64,
    data: [
      {
        complete: false,
        title: "Выберите статус проекта:",
        text: "Необходимо приступить к",
        selectedOption: null,
        type: "status",
        options: [
          {
            name: "Новый проект",
            icon: require("@/assets/icons/add-user.svg")
          },
          {
            name: "Существующий проект",
            icon: require("@/assets/icons/laptop.svg")
          },
        ]
      },
      {
        complete: false,
        title: "Выберите вид проекта:",
        text: "Это будет",
        selectedOption: null,
        type: "type",
        options: [
          {
            name: "Адаптивный сайт",
            icon: require("@/assets/icons/desktop.svg")
          },
          {
            name: "Мобильное приложение",
            icon: require("@/assets/icons/smartphone.svg")
          },
          {
            name: "CRM / ERP",
            icon: require("@/assets/icons/crm.svg")
          },
          {
            name: "B2B Сервис",
            icon: require("@/assets/icons/users.svg")
          },
          {
            name: "Портал",
            icon: require("@/assets/icons/globe.svg")
          },
          {
            name: "Комплексное решение",
            icon: require("@/assets/icons/cubes-white.svg")
          }
        ]
      },
      {
        complete: false,
        title: "Выберите направление деятельности:",
        text: "Посвященный",
        selectedOption: null,
        type: "line_of_business",
        options: [
          {
            name: "FinTech",
            icon: require("@/assets/icons/coins-white.svg")
          },
          {
            name: "EdTech",
            icon: require("@/assets/icons/user-graduate.svg")
          },
          {
            name: "Банки",
            icon: require("@/assets/icons/university.svg")
          },
          {
            name: "GR",
            icon: require("@/assets/icons/handshake-white.svg")
          },
          {
            name: "Ритейл",
            icon: require("@/assets/icons/cart-plus.svg")
          },
          {
            name: "e-commerce",
            icon: require("@/assets/icons/globe-americas.svg")
          },
          {
            name: "Медицина",
            icon: require("@/assets/icons/spa.svg")
          },
          {
            name: "Другое",
            icon: require("@/assets/icons/bolt.svg")
          },
        ]
      },
      {
        complete: false,
        title: "Введите Ваши контактные данные:",
        type: "companyData",
        companyData: {
          fields: [
            {
              label: "Название компании",
              value: null,
              type: "text",
              name: "company_name"
            },
            {
              label: "Имя и фамилия",
              value: null,
              type: "text",
              name: "name"
            },
            {
              label: "Телефон",
              value: null,
              type: "tel",
              mask: "+7 (###) ###-##-##",
              name: "phone"
            },
            {
              label: "Email",
              value: null,
              type: "text",
              name: "email"
            },
            {
              label: "Сроки запуска, бюджет, требования и прочие пожелания",
              value: null,
              textarea: true,
              type: "text",
              name: "description"
            },
          ]
        },
        file: null,
      },
    ]
  },
}

const getters = {
  REQUEST: state => state.request,
}

const mutations = {
  SET_REQUEST: (state, payload) => {
      state.request.data[payload.index].selectedOption = payload.data
  },
  SET_STEP: (state, payload) => {
    state.request.step = payload
  },
}

const actions = {
  SET_REQUEST: (context, payload) => {
    context.commit("SET_REQUEST", payload);
  },
  SET_STEP: (context, payload) => {
    context.commit("SET_STEP", payload);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
