<template>
  <section class="cases" id="cases">
    <div class="cases__container container">
      <h2 class="cases__title title">{{ data.title }}</h2>
      <ul class="type-of-work__tabs-buttons-list">
        <li class="type-of-work__tabs-buttons-item" v-for="(type, index) in data.types" :key="index">
          <button 
            :class="['type-of-work__tabs-button', activeTab == type.type ? 'active' : '']" 
            @click="setActiveTab(type.type)"
            >{{ type.name }}</button>
        </li>
      </ul>
    </div>
    <div class="cases__container container container--big" v-if="tabs.length > 0">
      <div :class="['cases__box', activeTab == tab.type ? 'active' : '' ]" v-for="(tab, index) in tabs" :key="index">
        <ul class="cases__list">
          <li :class="['cases__item', `cases__item--${item.class}`]" v-for="(item, index) in tab.data" :key="index"
            :style="{
              color: item.textColor,
              background: item.bg,
            }">
            <span class="cases__item-type">{{ item.type.name }}</span>
            <h3 class="cases__item-title" v-html="item.title"></h3>
            <p :class="['cases__item-description', item.columns ? 'two-columns' : '']" v-html="item.description"></p>
            <img v-if="item.logo" :src="item.logo.src" :alt="item.logo.alt" :width="item.logo.width" class="cases__item-logo">
          </li>
        </ul>
      </div>
    </div>
    <div class="top__bg-box">
      <img :src="data.bg.src" :alt="data.bg.alt" class="top__bg">
      <div class="container">
        <picture>
          <source media="(max-width: 1200px)" :srcset="data.bgText.srcMobile">
          <img :src="data.bgText.src" :alt="data.bgText.alt" class="top__bg-text">
        </picture>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      activeTab: "all"
    }
  },
  methods: {
    setActiveTab(name) {
      this.activeTab = name
    }
  },
  computed: {
    data() {
      return this.$store.getters.CASES
    },
    tabs() {
      let arr = []
      this.data.types.forEach(type => {
        arr.push({
          type: type.type,
          data: type.type == "all" ? this.data.list : this.data.list.filter(item => type.type == item.type.type)
        })
      })
      return arr
    }
  }
}
</script>

<style lang="less">
  .cases {
    padding-top: 160px;

    & .top__bg-box {
      margin: 0;
    }

    &__box {
      display: none;

      &.active {
        display: block;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 160px;
    }

    &__item {
      display: flex;
      flex-direction: column;

      height: 545px;
      border-radius: 10px;
      padding: 60px 72px;
      margin-bottom: 30px;

      box-sizing: border-box;
      background-repeat: no-repeat !important;

      &:nth-child(1n),
      &:nth-child(2n) {
        width: calc(50% - 15px);
      }

      &:nth-child(3n),
      &:nth-child(4n) {
        width: 100%;
      }

      &:nth-child(5n),
      &:nth-child(8n) {
        width: calc(40% - 15px);
      }

      &:nth-child(6n),
      &:nth-child(7n) {
        width: calc(60% - 15px);
      }

      &:nth-child(9n),
      &:nth-child(10n) {
        width: 100%;
      }

      &:nth-child(12n),
      &:nth-child(14n) {
        width: calc(50% - 15px);
      }

      &:nth-child(15n),
      &:nth-child(13n),
      &:nth-child(11n),
      &:nth-child(16n) {
        width: 100%;
      }

      &:nth-child(17n),
      &:nth-child(18n) {
        width: calc(50% - 15px);
      }

      &-type {
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;

        margin-bottom: 40px;
      }

      &-title {
        font-weight: bold;
        font-size: 30px;
        line-height: 110%;

        margin: 0;
        margin-bottom: 30px;
      }

      &-description {
        margin: 0;
        margin-bottom: auto;

        & ul {
          margin: 0;
          padding-left: 20px;
          list-style-type: disc;
        }

        &.two-columns {
          & ul {
            columns: 2;
            width: 100%;
            max-width: 800px;
          }

          & li {
            padding-right: 30px;
            padding-bottom: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .cases {
      &__item {
        width: 100% !important;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 890px) {
    .cases {
      padding-top: 35px;

      &__list {
        padding-bottom: 70px;
      }
    }
  }

  @media (max-width: 760px) {
    .cases {

      &__item {
        padding: 20px;
        min-height: 300px;
        height: auto;

        &-type {
          font-size: 14px;
          margin-bottom: 14px;
        }

        &-title {
          font-size: 20px;
          margin-bottom: 30px;
        }

        &-description {
          font-size: 16px;
          line-height: 120%;

          & ul {
            columns: 1 !important;
          }
        }

        &-logo {
          object-fit: contain;
          max-height: 56px;
          max-width: 105px;
        }
      }

      &__item {
        &--talanty {
          background-position: 75px 0, center !important;
        }
        &--pyaterochka {
          background-position: right -285px top 83px, center !important;
        }
        &--fssp {
          position: relative;
          & .cases__item-logo {
            position: absolute;
            right: 30px;
            bottom: 30px;

            max-height: 100px;
            max-width: 100px;
            width: 100%;
          }
        }
        &--sber {
          background-position: 185px 100%, center !important;
        }
        &--rzd {
          background-position: 100% + 30px 100%, center !important;
        }
        &--skillaz {
          background-position: right -70px bottom, center !important;
        }
        &--beauty {
          background-position: 248px 0, center !important;
          & .cases__item-description {
            padding-right: 100px;
          }
        }
        &--what-do-you-say {
          background-position: 245px 0, center !important;
        }
        &--window {
          background-position: bottom 50px right 20px, center !important;
        }
        &--deutsche-bank {
          background-position: 100% - 5px 100% - 5px, center !important;
        }
        &--moskow {
          background-position: 0 0, center !important;
        }
        &--bitrix {
          background-position: 100% - 10px 100% - 10px, center !important;
        }
      }
    }
  }
</style>