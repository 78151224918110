const state = {
    navigationLinks: [
        { path: { name: 'Home', hash: "#key-suggestions" }, name: "Ключевые предложения" },
        { path: { name: 'Home', hash: "#type-works" }, name: "Виды работ" },
        { path: { name: 'Home', hash: "#cases" }, name: "Кейсы" },
        { path: { name: 'Home', hash: "#contacts" }, name: "Контакты" },
    ],
}

const getters = {
    NAVIGATION_LINKS: state => state.navigationLinks,
}

export default {
	state,
	getters,
}