<template>
  <section class="type-of-work" id="type-works">
    <div class="type-of-work__container container">
      <h2 class="type-of-work__title title title--big">{{ data.title }}</h2>
      <ul class="type-of-work__tabs-buttons-list">
        <li class="type-of-work__tabs-buttons-item" v-for="(tab, index) in data.tabs" :key="index">
          <button :class="['type-of-work__tabs-button', activeTab == index ? 'active' : '']" @click="setActiveTab(index)">{{ tab.title }}</button>
        </li>
      </ul>
    </div>
    <div :class="['type-of-work__box', activeTab == index ? 'active' : '']" v-for="(tab, index) in data.tabs" :key="index">
      <div class="type-of-work__tabs container">
        <ul class="type-of-work__tabs-box">
          <li class="type-of-work__tabs-item" v-for="(content, index) in tab.list" :key="index">
            <h3 class="type-of-work__tabs-item-title">
              <span class="type-of-work__tabs-item-count">{{ index + 1 }}</span>
              <span class="type-of-work__tabs-item-title-text">{{ content.title }}</span>
            </h3>
            <ul class="type-of-work__tabs-item-content grid grid--3-columns">
              <li class="type-of-work__tabs-item-cell" v-for="(item, index) in content.list" :key="index">
                <template v-if="!item.link">
                  <img :src="item.icon" alt="icon" class="type-of-work__tabs-item-cell-icon">
                  <p class="type-of-work__text" v-html="item.description"></p>
                </template>
                <template v-else>
                  <a :href="item.link.href" class="type-of-work__tab-link" :style="{ backgroundColor: item.link.color }">
                    <span class="type-of-work__tab-link-text">{{ item.link.text }}</span>
                  </a>
                </template>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0
    }
  },

  methods: {
    setActiveTab(index) {
      this.activeTab = index
    }
  },

  computed: {
    data() {
      return this.$store.getters.TYPE_OF_WORK
    }
  }
}
</script>

<style lang="less">
  .type-of-work {
    padding-top: 95px;
    padding-bottom: 100px;

    &__title {
      max-width: 810px;
      margin-bottom: 36px;
    }

    &__tabs-buttons-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      margin-bottom: 60px;
      margin-left: -14px;
    }

    &__tabs-buttons-item {
      padding-right: 28px;
    }

    &__tabs-button {
      border: none;
      background-color: transparent;
      position: relative;

      font-weight: bold;
      font-size: 30px;
      line-height: 110%;
      color: var(--color-black);

      padding: 14px;

      transition: color 0.2s;

      &::before {
        content: "";
        position: absolute;

        width: 0;
        height: 4px;

        left: 14px;
        bottom: 0;

        border-radius: 4px;
        background-color: var(--color-blue);

        transition: width 0.4s;
        transition-timing-function: ease-in-out;
      }

      &:hover {
        color: var(--color-blue)
      }

      &.active {
        color: var(--color-blue);

        &::before {
          width: calc(100% - 28px);
        }
      }
    }

    &__box {
      display: none;
      padding: 60px 0;

      &.active {
        display: block;
      }

      &:nth-child(1n) {
        background-color: #FFFAF5;
      }

      &:nth-child(2n) {
        background-color: #F5F9FF;
      }

      &:nth-child(3n) {
        & .type-of-work {
          &__tabs-item-count {
            background-color: var(--color-orange);
          }
          &__tab-link {
            padding-top: 40px;
            padding-right: 140px;
            flex-direction: row;

            &::before {
              position: relative;
              bottom: 0;
              right: 0;
            }
          }
        }
      }
    }

    &__tabs-box {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__tabs-item-title {
      margin: 0;
      display: flex;
      align-items: center;

      font-weight: normal;
      font-size: 30px;
      line-height: 110%;
      color: var(--color-black);

      max-width: 750px;

      margin-bottom: 40px;

      &-text {
        padding-top: 5px;
      }
    }

    &__tabs-item-count {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 52px;
      min-width: 52px;
      height: 52px;
      color: var(--color-clear-white);

      background-color: var(--color-blue-light);
      border-radius: 50%;

      padding-top: 5px;
      margin-right: 41px;
      box-sizing: border-box;
    }

    &__tabs-item {
      &:not(:last-child) {
        & .type-of-work {
          &__tabs-item-content {
            margin-bottom: 60px;
          }
        }
      }
    }

    &__tabs-item-content {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__text {
      margin: 0;
      line-height: 110%;

      & ul {
        list-style-type: disc;
        margin: 0;
        margin-top: 20px;
        padding-left: 30px;
      }

      & li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      & a {
        color: var(--color-blue-light)
      }
    }

    &__tab-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      font-weight: normal;
      font-size: 30px;
      line-height: 110%;
      color: var(--color-black);
      text-decoration: none;

      width: 100%;
      height: 100%;

      padding: 40px 32px;
      padding-top: 90px;
      box-sizing: border-box;

      transition: opacity 0.2s;

      &::after {
        content: "";
        position: absolute;

        width: 54px;
        min-width: 54px;
        height: 54px;
        bottom: 40px;
        right: 32px;

        background-image: url("../assets/icons/arrow-to-right.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center right;
        
        margin-top: 9px;
        padding-left: 107px;

        transition: transform 0.3s ease-in-out;
      }

      &:hover {
        opacity: 0.8;

        &::after {
          transform: translateX(20px);
        }
      }
    }

    &__tabs-item-cell-icon {
      display: block;
      width: 32px;
      height: 32px;

      margin-bottom: 25px;
    }
  }

  @media (max-width: 890px) {
    .type-of-work {
      padding: 35px 0;

      &__tab-link {
        padding-bottom: 90px;
        padding-top: 40px;
      }
    }
  }

  @media (max-width: 629px) {
    .type-of-work {

      &__container {
        padding: 0;
      }

      &__title {
        padding: 0 20px;
        padding-right: 40px;
      }

      &__tabs-buttons-list {
        padding: 0 20px;
        padding-bottom: 20px;
        margin-bottom: 10px;
      }

      &__tabs-buttons-item {
        padding-right: 2px;
      }

      &__tabs-button {
        font-size: 24px;
        white-space: nowrap;
      }

      &__box {
        margin: 0 8px;
        padding: 30px 12px;

        &:nth-child(3n) {
          .type-of-work {
            &__tab-link {
              flex-direction: column;
            }
          }
        }
      }

      &__tabs {
        padding: 0;
      }

      &__tabs-item-title {
        align-items: flex-start;
        font-size: 24px;

        margin-bottom: 30px;
      }

      &__tabs-item-count {
        width: 29px;
        min-width: 29px;
        height: 29px;

        font-size: 16px;

        margin-right: 17px;
        padding-top: 3px;
      }

      &__tabs-item-cell-icon {
        margin-left: 46px;
        margin-bottom: 16px;
      }

      &__text {
        font-size: 16px;
        padding-left: 46px;

        & br {
          display: none;
        }

        & ul {
          text-align: left;
        }

        & li {
          margin: 0;
        }
      }

      &__tab-link {
        padding: 20px 30px;
        padding-right: 20px !important;

        &::after {
          position: relative;
          bottom: 0;
          right: 0;
          width: 32px;
          height: 32px;
          margin-left: auto;

          margin-top: -3px;
        }

        &:hover {
          &::after {
            transform: translateX(10px);
          }
        }
      }

      &__tab-link-text {
        width: calc(100% - 40px);
        margin-right: auto;
      }
    }
  }
</style>