const state = {
  suggestions: {
    title: "Уникальные предложения Preon.Tech",
    list: [
      {
        title: "Экономия от 30% расходов на IT",
        description: `
          Гарантируем* экономию <strong>от 30%</strong> ваших текущих расходов на IT-разработку при переводе разработки на Preon.Tech, за счет:
          <ul>
            <li>
              высокое качество кода -> <strong>0 доработок</strong> (большая база готовых решений и двойное ревью кода)
            </li>
            <li>
              <strong>быстрая</strong> разработка (выстроенные процессы и высокая квалификация разработчиков)
            </li>
            <li>
              <strong>нет лишних расходов:</strong> 0% административного персонала (все сотрудники заняты в проектах), open source решения для управления
            </li>
          </ul>
        `,
        description2: "* При невыполнении получите подарок: полный ИТ-аудит или 30 ч-д. ИТ-разработки",
        picture: {
          src: require("@/assets/images/suggestions-1.png"),
          width: 640
        }
      },
      {
        title: "Бесплатный IT-аудит",
        description: `
          Аудит займет <strong>до 3</strong> дней и в него войдет:
          <ul>
            <li>
              документ и очная презентация оценки:
              <ul>
                <li>
                  — оптимальности IT-архитектуры
                </li>
                <li>
                  — качества кода
                </li>
                <li>
                 — актуальности технологического стека
                </li>
                <li>
                  — оптимальности процессов разработки (Lean, Scrum, DevOps)
                </li>
                <li>
                  — прогноза производительности и потенциал масштабирования
                </li>
                <li>
                  — оценка рисков и ключевых уязвимостей
                </li>
              </ul>
            </li>
            <li>
              <strong>рекомендации</strong> по улучшениям*
            </li>
            <li>
              альтернативная оценка стоимости разработки силами Preon.Tech
            </li>
          </ul>
        `,
        description2: "* Возможен бонус: 5 часов консалтинга (it, бизнес) или UI/UX эксперта после аудита (от senior архитекторов + подберем профильного эксперта)",
        picture: {
          src: require("@/assets/images/suggestions-2.png"),
          width: 568
        }
      },
      {
        title: "MVP мобильного приложения за 30 дней",
        description: `
          Что войдет в ваш MVP:
          <ul>
            <li>
              до 5 блоков функциональности </br> (профиль, e-commerce, чаты, etc.)
            </li>
            <li>
              старт с нативных интерфейсов с адаптацией под проект (3 дня на дизайн) если у вас нет разработанных дизайнов
            </li>
            <li>
              параллельная регистрация в сторах </br> (AppStore, Google Market)
            </li>
          </ul>
        `,
        description2: `
          Быстрые процессы:
          <ul>
            <li>
              ТЗ и дизайны за 3–5 дней </br> (база готовых решений)
            </li>
            <li>
              online взаимодействие команды и заказчика (возможна 7 дневная рабочая неделя на период запуска)
            </li>
            <li>
              быстрое принятие решений </br> на стороне заказчика
            </li>
          </ul>
        `,
        picture: {
          src: require("@/assets/images/suggestions-3.png"),
          width: 646
        },
        twoColumns: true,
      },
    ]
  },
}

const getters = {
  SUGGESTIONS: state => state.suggestions,
}

export default {
state,
getters,
}