import Vue from "vue";
import Vuex from "vuex";

import navigationLinks from "./data/navigation"
import top from "./data/top"
import suggestions from "./data/suggestions"
import typeOfWork from "./data/typeOfWork"
import evaluation from "./data/evaluation"
import typeOfJobs from "./data/typeOfJobs"
import advatages from "./data/advantages"
import guarantees from "./data/guarantees"
import cases from "./data/cases"
// import staff from "./data/staff"
import footer from "./data/footer"
import request from "./data/request"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    navigationLinks,
    top,
    suggestions,
    typeOfWork,
    evaluation,
    typeOfJobs,
    advatages,
    guarantees,
    cases,
    // staff,
    footer,
    request
  },
});
