<template>
  <main className="main">
    <Header></Header>
    <top></top>
    <suggestions></suggestions>
    <type-of-work></type-of-work>
    <request-for-evaluation></request-for-evaluation>
    <type-of-jobs></type-of-jobs>
    <advantages></advantages>
    <guarantees></guarantees>
    <cases></cases>
     <staff></staff>
    <request-for-evaluation-second></request-for-evaluation-second>
  </main>
</template>

<script>
import Advantages from '../components/Advantages.vue'
import Cases from '../components/Cases.vue'
import Guarantees from '../components/Guarantees.vue'
import Header from '../components/Header.vue'
import RequestForEvaluation from '../components/RequestForEvaluation.vue'
import RequestForEvaluationSecond from '../components/RequestForEvaluationSecond.vue'
import Staff from '../components/Staff.vue'
import Suggestions from '../components/Suggestions.vue'
import Top from '../components/Top.vue'
import TypeOfJobs from '../components/TypeOfJobs.vue'
import TypeOfWork from '../components/TypeOfWork.vue'

export default {
  components: {
    Top,
    Suggestions,
    TypeOfWork,
    RequestForEvaluation,
    TypeOfJobs,
    Advantages,
    Guarantees,
    Cases,
    Staff,
    RequestForEvaluationSecond,
    Header
  },
}
</script>