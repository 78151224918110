<template>
  <section class="advantages bg-blue-dark">
    <div class="advantages__container container">
      <h2 class="advantages__title title">{{ data.title }}</h2>
      <ul class="advantages__list grid grid--3-columns">
        <li class="advantages__item" v-for="(item, index) in data.list" :key="index">
          <img :src="item.icon.src" :alt="item.icon.alt" class="advantages__item-icon">
          <h3 class="advantages__item-title">{{ item.title }}</h3>
          <p class="advantages__item-description" v-html="item.description"></p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.ADVANTAGES
    }
  }
}
</script>

<style lang="less">
  .advantages {
    padding: 80px 0;

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__item {
      &-icon {
        display: block;
        width: 20px;
        height: 20px;

        margin-bottom: 24px;
      }

      &-title {
        margin: 0;

        font-weight: bold;
        font-size: 30px;
        line-height: 110%;

        margin-bottom: 40px;
      }

      &-description {
        margin: 0;

        & ul {
          margin: 0;
          list-style-type: disc;
          padding-left: 20px;
        }

        & li {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 890px) {
    .advantages {
      &__item {

        &:not(:last-child) {
          padding-bottom: 20px;
        }
        
        &-icon {
          margin-bottom: 16px;
        }

        &-title {
          font-size: 24px;
          margin-bottom: 30px;
        }

        &-description {
          font-size: 16px;

          & li:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 440px) {
    .advantages {
      &__item {
        &-icon {
          margin: 0 auto;
          margin-bottom: 16px;
        }

        &-title {
          text-align: center;
        }
      }
    }
  }
</style>