<template>
  <section class="evaluation">
    <div class="evaluation__container container">
      <div class="evaluation__content grid grid--2-columns grid--middle">
        <div class="evaluation__box">
          <h2 class="evaluation__title">{{ data.title }}</h2>
          <p class="evaluation__description" v-html="data.description"></p>
          <send-request-button></send-request-button>
        </div>
        <div class="evaluation__box">
          <img :src="data.picture.src" :alt="data.picture.alt" width="541" class="evaluation__picture">
        </div>
      </div>
    </div>
    <div class="evaluation__bg-box">
      <img :src="data.bg.src" :alt="data.bg.alt" width="1920" class="evaluation__bg">
      <div class="container">
        <picture>
          <source media="(max-width: 1200px)" :srcset="data.bgText.srcMobile">
          <img :src="data.bgText.src" :alt="data.bgText.alt" width="1440" class="evaluation__bg-text">
        </picture>
      </div>
    </div>
  </section>
</template>

<script>
import SendRequestButton from './SendRequestButton.vue'
export default {
  components: { SendRequestButton },
  computed: {
    data() {
      return this.$store.getters.EVALUATION
    }
  }
}
</script>

<style lang="less">
  .evaluation {
    padding-top: 40px;

    &__box {
      &:first-child {
        padding-left: 90px;
        padding-right: 70px;
      }

      & .button {
        width: 100%;
        max-width: 300px;
      }
    }

    &__content {
      margin-bottom: 120px;
    }

    &__title {
      margin: 0;

      font-weight: bold;
      font-size: 50px;
      line-height: 110%;
      letter-spacing: -0.01em;

      margin-bottom: 40px;
    }

    &__description {
      margin: 0;
      margin-bottom: 40px;
      padding-right: 70px;

      & br {
        display: none;
      }
    }

    &__bg {
      position: absolute;
      object-fit: cover;

      width: 100%;
      height: 100%;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &-text {
        display: block;
        position: relative;
        z-index: 2;

        width: 100%;
        max-width: 1440px;
      }

      &-box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        width: 100%;
        height: 500px;

        overflow: hidden;
      }
    }

    &__picture {
      width: 100%;
      max-width: 541px;

      margin-top: -20px;
      margin-bottom: -70px;
    }
  }

  @media (max-width: 890px) {
    .evaluation {
      &__title {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 799px) {
    .evaluation {

      &__content {
        margin-bottom: 30px;
      }

      &__box {
        &:first-child {
          padding: 0;
        }
      }

      &__title,
      &__description {
        padding: 0;
        margin-bottom: 30px;
      }

      &__picture {
        min-width: 200px;
        display: block;
        margin: 0 auto;
      }
    }

    @media (max-width: 440px) {
      .evaluation {
        &__box {
          & .button {
            min-width: 100%;
          }
        }

        &__description {
          & br {
            display: block;
          }
        }
      }
    }
  }
</style>