<template>
  <section class="suggestions" id="key-suggestions">
    <div class="suggestions__container container">
      <h2 class="suggestions__title title">{{ data.title }}</h2>
      <ul class="suggestions__list">
        <li class="suggestions__item" v-for="(item, index) in data.list" :key="index">
          <h3 class="suggestions__item-title">{{ item.title }}</h3>
          <div :class="['suggestions__item-content', item.twoColumns ? 'two-columns' : '']">
            <div class="suggestions__item-box">
              <template v-if="!item.twoColumns">
                <p class="suggestions__item-description" v-html="item.description"></p>
              </template>
              <template v-else>
                <p class="suggestions__item-description">
                  <span v-html="item.description"></span>
                  <span v-html="item.description2"></span>
                </p>
              </template>
              <send-request-button></send-request-button>
            </div>
            <div class="suggestions__item-box">
              <img :src="item.picture.src" :alt="item.title" class="suggestions__item-picture" :width="item.picture.width">
              <p class="suggestions__item-description suggestions__item-description--small" v-if="!item.twoColumns" v-html="item.description2"></p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import SendRequestButton from './SendRequestButton.vue'
export default {
  components: { SendRequestButton },
  computed: {
    data() {
      return this.$store.getters.SUGGESTIONS
    }
  }
}
</script>

<style lang="less">
  .suggestions {

    padding-top: 35px;
    padding-bottom: 95px;

    &__title {
      margin-bottom: 45px;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__item {
      position: relative;
      
      padding: 50px 90px;
      border-radius: 10px;

      & .button {
        margin-top: 10px;
        padding: 13px;
        padding-bottom: 12px;

        width: 252px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &:nth-child(1n) {
        background-color: var(--color-green);
      }

      &:nth-child(2n) {
        background-color: var(--color-yellow);

        & .suggestions {
          &__item {
            &-picture {
              left: -70px;
              top: -30px;
            }
          }
        }
      }

      &:nth-child(3n) {
        background-color: var(--color-blue-sky);

        & .suggestions {
          &__item {
            &-box {
              width: 47%;

              &:last-child {
                justify-content: flex-start;
              }
            }

            &-picture {
              top: auto;
              left: auto;
              bottom: -185px;
              right: -180px;
            }

            &-description {
              font-size: 18px;

              &--small {
                width: calc(100% - 120px);
              }
            }
          }
        }
      }

      &-content {
        display: flex;
        justify-content: space-between;

        &.two-columns {
          flex-wrap: wrap;

          & .suggestions {
            &__item {
              &-box {
                width: 100%;
              }

              &-description {
                display: flex;
                justify-content: space-between;

                & > span {
                  width: 47%;

                  box-sizing: border-box;

                  &:last-child {
                    padding-right: 120px;
                  }
                }
              }
            }
          }
        }
      }

      &-title {
        margin: 0;

        color: var(--color-black);
        font-weight: bold;
        font-size: 40px;
        line-height: 110%;

        width: 50%;

        margin-bottom: 30px;
      }

      &-description {
        position: relative;
        z-index: 2;
        margin: 0;

        font-size: 18px;
        line-height: 110%;

        &--small {
          font-size: 14px;
          line-height: 110%;
        }

        &  ul {
          list-style-type: disc;
          padding-left: 20px;
          margin: 30px 0;

          & li {
            &:not(:last-child) {
              margin-bottom: 20px;
            }

            &  ul {
              margin: 0;
              padding: 0;
              list-style: none;

              & li {
                font-size: 14px;
                line-height: 110%;

                margin-top: 16px;
              }
            }
          }
        }
      }

      &-picture {
        position: absolute;

        top: -130px;
        left: -190px;
      }
    }

    &__item-box {
      position: relative;
      display: flex;
      flex-direction: column;

      width: 45%;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: 890px) {
    .suggestions {
      padding-top: 35px;
      padding-bottom: 35px;

      &__item {
        padding: 27px 30px;

        &-content.two-columns {
          & .suggestions {
            &__item-box {
              &:last-child {
                width: 47%;
                margin-left: auto;
                margin-right: 70px;
              }
            }
          }
        }

        &-picture {
          width: 160%;
        }
      }
    }
  }

  @media (max-width: 790px) {
    .suggestions {
      &__item {

        &:nth-child(1n) {
          & .suggestions {
            &__item {
              &-picture {
                width: 292px;
                margin-left: -30px;
              }
            }
          }
        }

        &:nth-child(2n) {
          & .suggestions {
            &__item {
              &-picture {
                width: 311px;
                margin-left: 0;
              }
            }
          }
        }

        &:nth-child(3n) {
          & .suggestions {
            &__item {
              &-picture {
                width: 325px;
              }
            }
          }
        }

        &-content {
          flex-direction: column;

          &.two-columns {
            & .suggestions {
              &__item {

                &-box {
                  &:last-child {
                    width: 100%;
                    margin-right: auto;
                    margin-left: -20px;
                  }
                }
                &-description {
                  flex-direction: column;

                  & > span {
                    width: 100%;

                    &:last-child {
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
        
        &-box {
          width: 100%;
        }

        &-title {
          width: 100%;

          text-align: center;
          font-size: 24px;

          padding: 0 20px;
          margin-bottom: 20px;

          box-sizing: border-box;
        }

        &-description {
          font-size: 16px;

          &--small {
            font-size: 14px;
          }
        }

        &-picture {
          position: relative;

          width: 100%;
          left: auto !important;
          right: auto !important;
          bottom: auto !important;
          top: auto !important;

          margin: 0 auto;
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .suggestions {
      &__item {
        & .button {
          width: 100%;
        }
      }
    }
  }
</style>