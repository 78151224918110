<template>
  <section class="type-of-jobs bg-blue-dark">
    <div class="type-of-jobs__container container">
      <h2 class="type-of-jobs__title title">{{ data.title }}</h2>
      <ul class="type-of-jobs__list grid grid--2-columns">
        <li class="type-of-jobs__item" v-for="(item, index) in data.list" :key="index">
          <h3 class="type-of-jobs__item-title">{{ item.title }}</h3>
          <p class="type-of-jobs__item-description" v-html="item.description"></p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.TYPE_OF_JOBS
    }
  }
}
</script>

<style lang="less">
  .type-of-jobs {
    padding-top: 100px;
    padding-bottom: 80px;

    &__title {
      font-size: 70px;
      text-align: center;
      margin-bottom: 70px;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__item {
      padding: 60px 100px;

      border-radius: 10px;
      background-color: var(--color-black-light);

      &:first-child {
        grid-column: ~"1 / 3";
      }

      &:nth-child(1n) {
        & .type-of-jobs {
          &__item {
            &-title {
              background: -webkit-linear-gradient(#BD79DC, #8D95E1);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            &-description {
              & ul {
                columns: 3;
              }
            }
          }
        }
      }

      &:nth-child(2n) {
        padding-right: 30px;

        & .type-of-jobs {
          &__item {
            &-title {
              background: -webkit-linear-gradient(#84DE89, #58C287);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            &-description {
              & ul {
                columns: 1;
              }
            }
          }
        }
      }

      &:nth-child(3n) {
        padding-right: 30px;

        & .type-of-jobs {
          &__item {
            &-title {
              background: -webkit-linear-gradient(#FE8E4C, #FF5D5B);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              min-height: 99px;
            }

            &-description {
              & ul {
                columns: 1;
              }
            }
          }
        }   
      }

      &-title {
        margin: 0;

        font-weight: bold;
        font-size: 30px;
        line-height: 110%;

        margin-bottom: 40px;
      }

      &-description {
        margin: 0;
        line-height: 110%;

        & ul {
          margin: 0;
          padding-left: 30px;
          list-style-type: disc;
          margin-bottom: -30px;
        }

        & li {
          display: inline-block;
          margin-bottom: 30px;
          padding-right: 20px;

          &::before {
            content: "";
            display: block;
            position: relative;

            top: 6px;
            left: -20px;
            width: 7.5px;
            height: 7.5px;
            margin-bottom: -7.5px;

            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
    }
  }

  @media (max-width: 890px) {
    .type-of-jobs {
      padding-top: 64px;
      padding-bottom: 35px;

      &__title {
        font-size: 48px;
        margin-bottom: 40px;
      }

      &__item {
        padding: 30px 20px !important;

        &:nth-child(3n) {
        padding-right: 30px;

          & .type-of-jobs {
            &__item {
              &-title {
                min-height: 78px;
              }
            }
          }   
        }

        &-title {
          font-size: 24px;
          margin-bottom: 30px;
        }

        &-text {
          font-size: 16px;

          & li {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 810px) {
    .type-of-jobs {
      &__item {
        &:first-child {
          grid-column: 1;
        }

        &:nth-child(3n) {
        padding-right: 30px;

          & .type-of-jobs {
            &__item {
              &-title {
                min-height: 0px;
              }
            }
          }   
        }
      }
    }
  }

  @media (max-width: 710px) {
    .type-of-jobs {
      &__item {
        &:nth-child(1n) {
          & .type-of-jobs {
            &__item {
              &-description {
                & ul {
                  columns: 2;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    .type-of-jobs {
      &__item {
        &:nth-child(1n) {
          & .type-of-jobs {
            &__item {
              &-description {
                & ul {
                  columns: 1;
                  padding-left: 20px;
                }
                
                & li {
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }

      &__item-description {
        & ul {
          margin-bottom: 0;
        }
      }
    }
  }
</style>