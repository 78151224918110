<template>
  <main class="main bg-blue-dark">
    <div class="header">
      <div class="container">
        <router-link :to="{ name: 'Home'}">
          <img :src="require('@/assets/logo-white.svg')" alt="Preon Tech" class="header__logo">
        </router-link>
      </div>
    </div>

    <section class="request">
      <div class="request__container container">
        <h1 class="request__title title">{{ data.title }}</h1>

        <div class="request__box">
          <form class="form" @submit.prevent="submit">
            <fieldset :class="['form__fieldset', data.step == index ? 'active' : '']" v-for="(form, index) in data.data" :key="index">
              <legend class="form__title">
                <template v-if="index != 0">
                  <button class="form__button-prew" @click="setPrewStep(index)"></button>
                </template>
                {{ form.title }}
              </legend>
              <template v-if="form.options">
                <div class="form__grid">
                  <label class="form__label" v-for="(option, index) in form.options" :key="index">
                    <input type="radio" class="form__radio visually-hidden" 
                      :value="option.name" v-model="form.selectedOption" @change="setNextStep"
                      aria-hidden="true" tabindex="-1">
                    <div class="form__radio-box">
                      <img :src="option.icon" :alt="option.name" class="form__radio-icon">
                      {{ option.name }}
                    </div>
                  </label>
                </div>
              </template>
              <template v-else>
                <div class="grid grid--2-columns">
                  <label :class="['form__label form__label--input', item.value ? 'active' : '',
                  item.textarea ? 'form__label--textarea' : '']" 
                  v-for="(item, index) in form.companyData.fields" :key="index">
                    <template v-if="!item.textarea">
                      <input :type="item.type" class="form__input" v-model="item.value" required v-if="!item.mask">
                      <the-mask class="form__input" :mask="item.mask" :type="item.type" :masked="false" v-model="item.value" v-else></the-mask>
                    </template>
                    <template v-else>
                      <textarea class="form__input form__input--textarea" cols="10" rows="5"  v-model="item.value" required></textarea>
                    </template>
                    <span class="form__label-text">{{ item.label }}</span>
                  </label>
                  <label class="form__label form__label--file">
                    <img :src="require('@/assets/icons/paperclip.svg')" alt="Скрепка" class="form__file-icon">
                    <span class="form__file-text">{{ fileName }}</span>
                    <span class="form__file-info">до {{ data.maxFileSizeInMb }}MB</span>
                    <input type="file" @change="previewFiles" class="form__file visually-hidden" aria-hidden="true" tabindex="-1">
                  </label>
                  <button class="button" type="submit">Отправить запрос</button>
                  <label class="form__label form__label--checkbox">
                    <input type="checkbox" class="form__checkbox visually-hidden" aria-hidden="true" tabindex="-1" required>
                    <span class="form__checkbox-indicator"></span>
                    <span>
                      Нажимая на кнопку, вы соглашаетесь на 
                      <a href="" target="_blank" class="link">pобработку персональных данных</a>
                    </span>
                  </label>
                </div>
              </template>
            </fieldset>
          </form>

          <div class="request__step-box">
            <div class="request__step-count-box">
              <p class="request__step-count">
                <span>{{data.step + 1}}</span> <span>/ {{ data.data.length }}</span>
              </p>
              <div class="request__step-indicator-box">
                <div :class="['request__step-indicator', data.step + 1 >= Number.parseInt(item) ? 'active' : '']" v-for="item in data.data.length" :key="item"></div>
              </div>
              <p class="request__step-title">
                Ваша задача:
              </p>
              <ul class="request__step-list">
                <template v-for="(item, index) in data.data">
                  <li class="request__step-item" :key="index" v-if="item.selectedOption">
                    {{ item.text }}
                    <div class="badge">
                      {{ item.selectedOption }}
                      <button class="badge__button" @click="removeOption(index)"></button>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { TheMask } from 'vue-the-mask'

export default {
  components: { TheMask },
  data() {
    return {
      file: null,
      fileName: "Прикрепить файл"
    }
  },
  methods: {
    previewFiles(event) {
      const file = event.target.files[0]
      if (file) {
        const fileSizeInMd = (file.size / 1024 /1024).toFixed(4)
        console.log(fileSizeInMd);

        if (fileSizeInMd < this.data.maxFileSizeInMb) {
          this.file = file
          this.fileName = file.name
        } else {
          this.$notify({
            group: 'main',
            type: 'warn',
            title: `Размер отправляемого файла не должен превышать ${this.data.maxFileSizeInMb}МБ`,
          });
        }
      } else {
        this.fileName = "Прикрепить файл"
        this.file = null
      }
    },
    async submit() {
      const data = this.data
      const bodyFormData = new FormData();
      if (this.file != null) {
        bodyFormData.append("file", this.file)
      }
      await data.data.forEach(item => {
        if (item.type != "companyData") {
          bodyFormData.append(item.type, item.selectedOption);
        } else {
          item.companyData.fields.forEach(company => {
            if (company.type != "tel") {
              bodyFormData.append(company.name, company.value);
            } else {
              bodyFormData.append(company.name, `+7${company.value}`);
            }
          })
        }
      })

      await this.axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/api/v1/applications`,
        headers: {'Content-Type': `multipart/form-data`},
        data: bodyFormData
      }).then(response => {
        if (response.data.status == 200) {
          this.$notify({
            group: 'main',
            title: 'Ваш запрос успешно отправлен',
          });
        }
      }).catch(error => {
        const errors = error.response.data.error.fields
        Object.entries(errors).forEach(entry => {
          // const [key, value] = entry;
          this.$notify({
            group: 'main',
            title: entry[1][0],
            type: 'error',
          });
        });
      })

    },
    setNextStep() {
      let nextStep = this.data.step

      for (let [index, item] of this.data.data.entries()) {
        if (!item.selectedOption) {
          nextStep = index
          this.$store.dispatch("SET_STEP", nextStep)
          return
        }
      }
    },
    setPrewStep(index) {
      this.$store.dispatch("SET_STEP", this.data.step - 1)
      this.$store.dispatch("SET_REQUEST", { index: index - 1, data: null })
    },
    removeOption(index) {
      this.$store.dispatch("SET_STEP", index)
      this.$store.dispatch("SET_REQUEST", { index: index, data: null })
    }
  },
  computed: {
    data() {
      return this.$store.getters.REQUEST
    },
  }
}
</script>

<style lang="less">
  .request {
    &__title {
      font-weight: bold;
      font-size: 50px;
      line-height: 110%;
      letter-spacing: -0.01em;
      margin-bottom: 30px;
    }

    &__box {
      display: flex;
    }

    &__step {
      &-box {
        width: 566px;
        min-height: 680px;

        margin-left: 140px;
        margin-right: -240px;

        background-color: #1A065F;
        border-radius: 10px;

        padding: 105px 50px;
        box-sizing: border-box;
      }

      &-count {
        margin: 0;
        display: flex;
        align-items: flex-start;

        & span {
          &:first-child {
            font-size: 35px;
            line-height: 110%;
            letter-spacing: 0.1em;
            margin-right: 5px;
          }

          &:last-child {
            font-size: 17px;
            line-height: 110%;
            letter-spacing: 0.1em;

            color: rgba(255, 255, 255, 0.5);
          }
        }
      }

      &-indicator-box {
        display: flex;
        align-items: center;

        width: 238px;
        height: 10px;

        background: #000000;
        border-radius: 10px;

        margin-left: 40px;
        margin-top: -14px;
        padding: 0 2px;

        box-sizing: border-box;
      }
      
      &-indicator {
        flex-grow: 1;
        height: 4px;
        background: transparent;
        border-radius: 10px;

        margin: 0 2px;

        transition: background-color 0.2s;

        &.active {
          background: #2A5EE9;
        }
      }
    }

    &__step-title {
      margin: 0;
      font-size: 30px;
      line-height: 110%;
      letter-spacing: -0.01em;
      margin-top: 30px;
      margin-bottom: 40px;
    }

    &__step-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
    }

    &__step-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      font-size: 16px;
      line-height: 130%;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      & .badge {
        margin-top: 14px;
      }
    }
  }

  .badge {
    display: flex;
    align-items: center;

    color: var(--color-blue);
    text-transform: lowercase;

    background: #FFFFFF;
    border-radius: 20px;

    padding: 8px 15px;

    &__button {
      padding: 0;
      border: none;
      background-color: transparent;
      display: block;

      width: 18px;
      min-width: 18px;
      height: 18px;

      margin-left: 10px;

      background-image: url("../assets/icons/badge-close.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .form {
    width: 100%;
    max-width: 725px;
    padding-bottom: 230px;

    & .grid--2-columns {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-row-gap: 40px;
      grid-column-gap: 20px;
    }

    &__fieldset {
      display: none;

      border: none;
      padding: 0;
      margin: 0;
      // padding-left: 60px;

      &.active {
        display: block;
      }
    }

    &__title {
      position: relative;
      display: flex;
      align-items: center;

      font-size: 30px;
      line-height: 110%;
      letter-spacing: -0.01em;
      margin-bottom: 50px;
      padding: 0;
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;

      max-width: 526px;
      width: 100%;
    }

    &__label {
      position: relative;

      display: flex;
      flex-direction: column;
      cursor: pointer;

      &--input {
        padding-top: 23px;
      }

      &--textarea {
        grid-column: ~"1 / 3";
      }

      &--checkbox {
        grid-column: ~"1 / 3";
        flex-direction: row;

        font-size: 14px;
        line-height: 130%;
      }

      &--file {
        flex-direction: row;
        align-items: center;
      }

      &:hover .form__radio,
      &:focus .form__radio {
        &-box {
          background-color: var(--color-blue);
        }
      }

      &.active {
        & .form__label-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 31px);
          box-sizing: border-box;
          top: 0px;
        }
      }

      &-text {
        position: absolute;
        z-index: 1;

        font-size: 16px;
        line-height: 130%;
        opacity: 0.5;

        left: 31px;
        top: 38px;

        padding-right: 20px;

        transition: top 0.2s ease-in-out;
      }
    }

    &__input {
      position: relative;
      z-index: 2;
      padding: 14px 30px;
      font-size: 16px;
      line-height: 130%;
      color: #fff;
      background-color: transparent;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 20px;
      outline: none;

      &--textarea {
        resize: vertical;
      }

      &:focus {
        &~.form__label-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 31px);
          box-sizing: border-box;
          top: 0px;
        }
      }
    }

    &__file {
      &-icon {
        width: 16px;
        min-width: 16px;
        height: 18px;
        margin-right: 10px;
      }

      &-text {
        font-weight: bold;
        font-size: 16px;
        line-height: 130%;
        text-decoration: underline;

        margin-right: 7px;
      }

      &-info {
        font-size: 16px;
        line-height: 130%;
        opacity: 0.5;
      }
    }

    &__radio {
      
      &:checked~.form__radio {
        &-box {
          background-color: var(--color-blue);
        }
      }

      &-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 81px;
        font-size: 16px;
        line-height: 130%;
        text-align: center;

        background-color: var(--color-black-light);
        border-radius: 10px;

        padding: 18px 26px;
        padding-right: 10px;

        box-sizing: border-box;

        transition: background-color 0.2s;
      }

      &-icon {
        display: block;
        margin-right: 16px;
      }
    }

    &__checkbox {

      &:checked~.form__checkbox-indicator {
        background-image: url("../assets/icons/check.svg");
        background-repeat: no-repeat;
        background-position: center;
      }

      &-indicator {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 16px;
        min-width: 16px;
        height: 16px;

        border: 1px solid #FFFFFF;

        margin-right: 12px;
      }
    }

    &__button-prew {
      // position: absolute;
      padding: 0;
      border: none;
      display: block;
      background-color: transparent;

      width: 30px;
      height: 30px;
      // left: -60px;
      // top: 50%;
      // transform: translateY(-50%);

      background-image: url("../assets/icons/arrow-prew.svg");
      background-size: contain;
      background-position: center;

      margin-right: 30px;

      transition: opacity 0.2s;

      &:hover,
      &:focus {
        opacity: 0.4;
      }
    }
  }

  @media (max-width: 1320px) {
    .request {
      &__step-box {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 1120px) {
    .request {
      &__step-box {
        margin-right: -30px;
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 890px) {
    .request {
      &__step-box {
        display: none;
      }

      &__title {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }

    .form {
      max-width: 100%;

      &__title {
        height: 29px;
        font-size: 20px;
        margin-bottom: 30px;
      }

      &__fieldset {
        padding: 0;
      }

      &__button-prew {
        left: 0;

        width: 29px;
        min-width: 29px;
        height: 29px;
        min-height: 29px;
      }
    }
  }

  @media (max-width: 570px) {
    .form {
      padding-bottom: 100px;

      & .grid--2-columns {
        grid-template-columns: 1fr;
        grid-gap: 26px;
      }
      &__label {
        &--textarea {
          grid-column: 1;
        }

        &--checkbox {
          grid-column: 1;
        }
      }

      &__radio-box {
        padding-right: 18px;
      }
    }
  }

  @media (max-width: 470px) {
    .form {
      &__radio-box {
        flex-direction: column;
        align-items: center;

        height: 104px;
        padding: 18px 8px;

      }

      &__radio-icon {
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
</style>