const state = {
  guarantees: {
    title: "Гарантии Preon.Tech для партнеров",
    list: [
      {
        description: "100% покрытие тестами",
        bg: require("@/assets/images/guarantees-1.jpg")
      },
      {
        description: "Возврат 20% стоимости проекта в случае задержки на 1 день и 100% в случае задержки на >10 дней",
        bg: require("@/assets/images/guarantees-2.jpg")
      },
      {
        description: "100% прозрачность: доступ Real-time к отчетности и рабочей среде",
        bg: require("@/assets/images/guarantees-3.jpg")
      },
    ]
  },
}

const getters = {
  GUARANTEES: state => state.guarantees,
}

export default {
  state,
  getters,
}