<template>
  <section class="staff bg-blue-dark">
    <div class="staff__container container">
      <div class="staff__top">
        <h2 class="staff__title title" v-html="data.title"></h2>
        <img :src="data.picture" alt="Clouds" class="staff__picture">
      </div>
      <ul class="staff__list grid grid--2-columns">
        <li class="staff__item" v-for="(item, index) in data.list" :key="index">
          <div class="staff__item-header">
            <div class="staff__item-avatar-box">
              <img :src="item.avatar" :alt="item.name" class="staff__item-avatar">
            </div>
            <p class="staff__item-name">
              <span class="staff__item-position">{{ item.position }}</span>
              {{ item.name }}
            </p>
          </div>
          <p class="staff__item-description" v-html="item.description"></p>
          <p class="staff__item-title">ОБРАЗОВАНИЕ</p>
          <p class="staff__item-description" v-html="item.education"></p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.STAFF
    }
  }
}
</script>

<style lang="less">
  .staff {
    padding: 160px 0;

    &__top {
      display: flex;
      justify-content: space-between;

      margin-bottom: 80px;
      padding-right: 100px;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__item {
      background-color: var(--color-black-light);
      border-radius: 10px;
      padding: 40px 70px;

      &-header {
        display: flex;
        align-items: flex-end;

        margin-bottom: 40px;
      }

      &-avatar-box {
        width: 99px;
        min-width: 99px;
        height: 99px;

        margin-right: 33px;

        border-radius: 50%;
        overflow: hidden;
      }

      &-avatar {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &-name {
        margin: 0;
        font-weight: bold;
        font-size: 30px;
        line-height: 110%;
      }

      &-position {
        display: block;

        font-size: 16px;
        line-height: 110%;
        letter-spacing: -0.01em;
        color: #636363;

        margin-bottom: 14px;
      }

      &-title {
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 110%;
        letter-spacing: -0.01em;
        text-transform: uppercase;

        color: #636363;

        margin-bottom: 20px;
      }

      &-description {
        margin: 0;

        font-size: 16px;
        line-height: 110%;

        & ul {
          margin: 0;
          list-style-type: disc;
          padding-left: 20px;
        }

        & li {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }

  @media (max-width: 890px) {
    .staff {
      padding: 60px 0;

      &__top {
        justify-content: center;
        padding-right: 0;
        margin: 0;
      }

      &__picture {
        display: none;
      }

      &__title {
        text-align: center;
      }

      &__list {
        grid-template-columns: 1fr;
      }

      &__item {
        padding: 30px 20px;
      }
    }
  } 
  @media (max-width: 530px) {
    .staff {
      &__item {
        &-header {
          margin-bottom: 30px;
        }

        &-avatar-box {
          width: 70px;
          min-width: 70px;
          height: 70px;

          margin-right: 16px;
        }

        &-name {
          font-size: 20px;
        }

        &-description {
          font-size: 16px;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        &-title {
          font-size: 16px;
        }
      }
    }
  }
</style>