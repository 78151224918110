<template>
  <section class="guarantees bg-blue-dark">
    <div class="guarantees__container container">
      <h2 class="guarantees__title title">{{ data.title }}</h2>
      <ul class="guarantees__list grid grid--3-columns">
        <li class="guarantees__item" v-for="(item, index) in data.list" :key="index"
         :style="{ backgroundImage: `url(${item.bg})` }">{{ item.description }}</li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.GUARANTEES
    }
  }
}
</script>

<style lang="less">
  .guarantees {
    padding-top: 80px;
    padding-bottom: 160px;

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__item {
      height: 380px;

      font-size: 26px;
      line-height: 110%;
      color: #fff;

      border-radius: 8px;
      background-color: var(--color-black);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center right;
      padding: 60px 27px;
      box-sizing: border-box;
    }
  }

  @media (max-width: 890px) {
    .guarantees {
      &__item {
        font-size: 24px;
        padding: 60px 20px;
      }
    }
  }

  @media (max-width: 600px) {
    .guarantees {

      &__title {
        text-align: center;
      }

      &__list {
        grid-gap: 0;
      }

      &__item {
        height: 273px;
      }
    }
  }
</style>