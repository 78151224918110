const state = {
  top: {
    title: "IT-разработчик эталонных digital-решений для startup и крупного бизнеса",
    description1: "<strong>Быстрее рынка</strong> запустим ваш ИТ-проект и сразу сэкономим ваши расходы на действующее ИТ-решение на <strong>30%</strong> и выше",
    description2: "FinTech, EdTech, банки, GR,</br> ритейл, e-commerce,</br> медицина и промышленность",
    image1: {
      src: require("@/assets/images/clouds-main.png"),
      srcMobile: require("@/assets/images/clouds-main-white-text.svg"),
      alt: "пройден аудит Google Developers в 2020",
      components: [
        require("@/assets/images/main-cloud-comp-1.svg"),
        require("@/assets/images/main-cloud-comp-2.svg"),
        require("@/assets/images/main-cloud-comp-3.png"),
      ]
    },
    image2: {
      src: require("@/assets/images/top-image.png"),
      alt: "200+ партнёров"
    },
    bg: {
      src: require("@/assets/images/top-bg.jpg"),
      alt: "PREON TECH"
    },
    bgText: {
      src: require("@/assets/images/top-bg-text-desktop.png"),
      srcMobile: require("@/assets/images/top-bg-text-mobile.png"),
      alt: "PREON TECH"
    },
    subTitle: "3 ключевые цифры на июль 2021г",
    advantages: [
      {
        title: "70+",
        description: "Разработчиков уровня middle и senior с опытом от 3 лет"
      },
      {
        title: ">200",
        description: "Реализованных проектов компанией и участниками команд в 2015-2021"
      },
      {
        title: "91%",
        description: "NPS заказчиков по реализованным проектам в 1 полугодии 2021"
      },
    ]
  },
}

const getters = {
  TOP: state => state.top,
}

export default {
  state,
  getters,
}