const state = {
  footer: {
    logo: {
      src: require("@/assets/logo.png"),
      alt: "Preon tech",
      width: 180,
    },
    picture: {
      src: require("@/assets/images/clouds-footer.png"),
      alt: "Облока",
      width: 357
    },
    links: [
      {
        name: "Политика конфеденциальности",
        hreh: ""
      },
      {
        name: "Публичная оферта",
        hreh: ""
      },
    ],
    requisites: [
      "ИНН 7707448752",
      "ОГРН 1217700056640"
    ],
    partner: {
      text: "Preon.Tech – партнер",
      logo: {
        src: require("@/assets/images/e-legion.jpg"),
        alt: "e legion",
        width: 143
      }
    },
    copyright: "(с) “Preon.Tech” 2021 "
  },
}

const getters = {
  FOOTER: state => state.footer,
}

export default {
state,
getters,
}