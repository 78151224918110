const state = {
  typeOfJobs: {
    title: "Виды работ",
    list: [
      {
        title: "Заказная ИТ-разработка для B2B и GR",
        description: `
          <ul>
            <li>
              Мобильная и web разработка полного цикла (маркетплейсы, личные кабинеты, app для сотрудников, AR-приложения, система лояльности, маты и мессенджеры)
            </li>
            <li>
              Построение микросервисной архитектуры и разработка высоконагруженных приложений
            </li>
            <li>
              Аудит, проектирование и реинжиниринг IT-систем
            </li>
            <li>
              Разработка на front-end (React, Angular, extJS)
            </li>
            <li>
              Системы с биометрической идентификацией
            </li>
            <li>
              Разработка на back-end (Java, Python, R, C++, SQL, NoSQL, Postgres): oCRM / aCRM, ERP
            </li>
            <li>
              Система с Artificial Intelligence / Machine learning
            </li>
          </ul>
        `
      },
      {
        title: "Консалтинг бизнес-процессов внутри разработки (↓Time To Market: CI / CD / DevOps)",
        description: `
          <ul>
            <li>
              Аудит модели процессов</br> (коммуникации,</br> спринт-планнинг)
            </li>
            <li>
              Аудит процессов от 5 дней
            </li>
            <li>
              Построение процессов</br> за 2 релиза
            </li>
          </ul>
        `
      },
      {
        title: "QA: построение систем тестирования",
        description: `
          <ul>
            <li>
              Исключаем блокирующие</br> ошибки / дефекты системы
            </li>
            <li>
              QA-консалтинг и организация</br>  тестирований
            </li>
            <li>
              Внедряем технологии</br> DevOps, CI / CD
            </li>
          </ul>
        `
      },
    ]
  },
}

const getters = {
  TYPE_OF_JOBS: state => state.typeOfJobs,
}

export default {
  state,
  getters,
}