const state = {
  advantages: {
    title: "Преимущества Preon.Tech:",
    list: [
      {
        title: "Скорость старта работ",
        icon: {
          src: require("@/assets/icons/rocket-white.svg"),
          alt: "Рокета"
        },
        description: `
          <ul>
            <li>
              оценка проекта до 2 дней (за счет наличия профильных ИТ-архитекторов)
            </li>
            <li>
              сбор команды и старт работ 5-10 дней
            </li>
            <li>
              высокая финансовая стабильность – старт работ без предоплат
            </li>
          </ul>
        `
      },
      {
        title: "Стоимость разработки",
        icon: {
          src: require("@/assets/icons/coins-white.svg"),
          alt: "Монеты"
        },
        description: `
          <ul>
            <li>
              стоимость разработки min на 30% ниже, чем в среднем по рынку
            </li>
            <li>
              min кол-во доработок за счет высокого качества кода (2е ревью)
            </li>
            <li>
              плоская орг. структура – у нас 0 расходы на административный персонал (все сотрудники Preon.Tech участвуют в проектах)
            </li>
            <li>
              мы используем open source решения для управления процессом разработки
            </li>
          </ul>
        `
      },
      {
        title: "Эталонное качество кода",
        icon: {
          src: require("@/assets/icons/trophy-white.svg"),
          alt: "Кубок"
        },
        description: `
          <ul>
            <li>
              эталонное качество кода и самая современная ИТ-архитектура
            </li>
            <li>
              двойное ревью кода с подключением разработчиков из ведущих ИТ-компаний
            </li>
            <li>
              большая база разработанных решений (микросервисы)
            </li>
            <li>
              высокая проработка тех. требований на этапе проектирования
            </li>
          </ul>
        `
      },
    ]
  },
}

const getters = {
  ADVANTAGES: state => state.advantages,
}

export default {
  state,
  getters,
}