<template>
  <footer class="footer" id="contacts">
    <div class="footer__container container">
      <div class="footer__top">
        <img :src="data.logo.src" :alt="data.logo.alt" :width="data.logo.width" class="footer__logo">
<!--        <p class="footer__partner mobile-width">-->
<!--          {{ data.partner.text }}-->
<!--          <img :src="data.partner.logo.src" :alt="data.partner.logo.alt" :width="data.partner.logo.width" class="footer__partner-logo">-->
<!--        </p>-->
        <img :src="data.picture.src" :alt="data.picture.alt" :width="data.picture.width" class="footer__picture">
      </div>
      <ul class="footer__list grid grid--3-columns">
        <li class="footer__item">
          <navigation class="footer__nav"></navigation>
        </li>
        <li class="footer__item">
          <a :href="link.href" target="_blank" class="footer__link" v-for="(link, index) in data.links" :key="index">
            {{ link.name }}
          </a>
        </li>
        <li class="footer__item">
          <p class="footer__text" v-for="(text, index) in data.requisites" :key="index">
            {{ text }}
          </p>
        </li>
      </ul>
<!--      <p class="footer__partner desktop-width">-->
<!--        {{ data.partner.text }}-->
<!--        <img :src="data.partner.logo.src" :alt="data.partner.logo.alt" :width="data.partner.logo.width" class="footer__partner-logo">-->
<!--      </p>-->
    </div>

    <div class="footer__copyright">
      {{ data.copyright }}
    </div>
  </footer>
</template>

<script>
import Navigation from './Navigation.vue'
export default {
  components: { Navigation },
  computed: {
    data() {
      return this.$store.getters.FOOTER
    }
  }
}
</script>

<style lang="less">
  .footer {
    padding-top: 58px;
    padding-bottom: 40px;
    
    background: #F2F2F2;

    & .mobile-width {
      display: none;
    }

    &__container {
      position: relative;
    }

    &__picture {
      position: absolute;
      right: 0;
      top: -130px;
    }

    &__nav {
      & .nav {
        &__list {
          flex-direction: column;
        }

        &__item {
          &:last-child {
            display: none;
          }
        }
      }
    }

    &__list {
      padding: 0;
      margin: 0;
      list-style: none;

      padding-right: 220px;

      margin-top: 30px;
      margin-bottom: 70px;
    }

    &__link,
    &__text {
      margin: 0;
      display: block;

      font-size: 16px;
      line-height: 24px;
      opacity: 0.6;

      padding: 0 20px;
      margin-top: 10px;
    }

    &__partner {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #000;

      margin-bottom: 30px;

      &-logo {
        margin-left: 14px;
      }
    }

    &__copyright {
      width: calc(100% - 40px);
      max-width: 1287px;

      text-align: center;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000;

      border-top: 1px solid #000;

      padding-top: 30px;
      margin: 0 auto;
    }
  }

  @media (max-width: 650px) {
    .footer {
      padding-top: 240px;

      &--request {
        display: none;
      }

      & .mobile-width {
        display: flex;
      }

      & .desktop-width {
        display: none;
      }

      &__picture {
        top: -260px;
        right: 50%;
        transform: translateX(50%);
      }

      &__top {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 30px;
      }

      &__partner {
        margin: 0;
      }

      &__logo {
        margin-bottom: 17px;
      }

      &__list {
        padding: 0;
        grid-template-columns: 1fr !important;
        grid-gap: 0 !important;
        margin: 0 auto;
        margin-bottom: 40px;
        max-width: 372px;
      }

      &__nav {
        margin-bottom: 30px;

        & .nav {
          &__item {
            padding: 0;
          }
        }
      }

      &__link, 
      &__text  {
        padding: 0 10px;
      }
    }
  }
</style>