import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification';

Vue.config.productionTip = false;
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(VueAxios, axios)
Vue.use(Notifications)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
