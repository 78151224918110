import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Request from "@/views/Request.vue"
import VueScrollTo from "vue-scrollto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/request",
    name: "Request",
    component: Request,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
        VueScrollTo.scrollTo(to.hash, 700);
        return { selector: to.hash }
    } else if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }
  }
});

export default router;
