<template>
  <nav class="nav">
    <ul class="nav__list">
      <li class="nav__item" v-for="(link, index) in data" :key="index">
        <router-link :to="link.path" class="nav__link">{{ link.name }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
      data() {
          return this.$store.getters.NAVIGATION_LINKS
      }
  }
}
</script>

<style lang="less">
  .nav {
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

      display: flex;
      justify-content: center;
    }

    &__item {
      padding: 0 12px;
    }

    &__link {
      display: block;
      text-decoration: none;
      color: var(--color-black);
      font-size: 16px;
      line-height: 110%;

      padding: 10px;

      transition: color 0.2s;

      &:hover,
      &:focus {
        color: var(--color-blue-light);
      }
    }
  }
</style>