const state = {
  cases: {
    title: "Кейсы",
    bg: {
      src: require("@/assets/images/cases-bg.jpg"),
      alt: "PREON TECH"
    },
    bgText: {
      src: require("@/assets/images/top-bg-text-desktop.svg"),
      srcMobile: require("@/assets/images/top-bg-text-mobile.svg"),
      alt: "PREON TECH"
    },
    types: [
      {
        name: "Все",
        type: "all"
      },
      {
        name: "Startup",
        type: "startup"
      },
      {
        name: "B2B",
        type: "b2b"
      },
      {
        name: "B2G",
        type: "b2g"
      },
    ],
    list: [
      // {
      //   type: {
      //     name: "B2B",
      //     type: "b2b"
      //   },
      //   class: "x5",
      //   title: `
      //     Запуск целевого</br>
      //     маркетинга в X5 </br>
      //     Retail Group
      //   `,
      //   description: `
      //     Reinforcement learning;</br> 80 млн. акций за 1 мес.
      //   `,
      //   logo: {
      //     src: require("@/assets/images/x5-logo.png"),
      //     alt: "X5",
      //     width: 71
      //   },
      //   bg: `bottom right / contain url(${require("@/assets/images/x5-bg.png")}), #5FAF2D`,
      //   textColor: "#fff"
      // },
      // {
      //   type: {
      //     name: "B2G",
      //     type: "b2g"
      //   },
      //   class: "rosstat",
      //   title: "Росстат",
      //   description: `
      //   Проекты реализации </br>
      //   обработки данных
      //   `,
      //   logo: {
      //     src: require("@/assets/images/rosstat-logo.png"),
      //     alt: "Росстат",
      //     width: 64
      //   },
      //   bg: `center / cover url(${require("@/assets/images/rosstat-bg.jpg")}), #000`,
      //   textColor: "#fff"
      // },
      {
        type: {
          name: "Startup",
          type: "startup"
        },
        class: "talanty",
        title: `
          Разработка платформы</br> Talanty.Online
        `,
        description: `
          <ul>
            <li>
              текущая оценка 250 млн ₽ (вход</br> от инвесторов)
            </li>
            <li>
              поддержка «Фонда содействия</br> инновациям» (3 млн руб.)
            </li>
            <li>
              проект поддержан Министерством</br> Просвещения РФ
            </li>
          </ul>
        `,
        bg: `center left 194px / auto 100% url(${require("@/assets/images/talanty-bg.png")}), linear-gradient(287.37deg, #8CA7F4 15.88%, #5376D9 60.11%)`,
        textColor: "#fff"
      },
      // {
      //   type: {
      //     name: "B2B",
      //     type: "b2b"
      //   },
      //   class: "pyaterochka",
      //   title: `
      //     Цифровая CX-трансформация </br>
      //     ТС «Пятёрочка»
      //     `,
      //   description: `
      //     19 новых ИТ решений; ↑ MAU моб.</br> приложения Пятёрочки с 2 до 6,2 млн)
      //   `,
      //   logo: {
      //     src: require("@/assets/images/pyaterochka-logo.svg"),
      //     alt: "Пятёрочка",
      //     width: 202,
      //   },
      //   bg: `center left 536px / auto 100% url(${require("@/assets/images/pyaterochka-bg.png")}), linear-gradient(255.8deg, rgba(213, 28, 19, 0.2) 39.9%, rgba(255, 93, 91, 0.2) 92.91%), #E74E25`,
      //   textColor: "#fff"
      // },
      // {
      //   type: {
      //     name: "B2G",
      //     type: "b2g"
      //   },
      //   class: "fssp",
      //   title: `
      //     ФССП </br>
      //     России
      //   `,
      //   description: `
      //     Доработка ИТсервисов,</br> тех. стек: PHP, JQuery, JS,</br> mysql
      //   `,
      //   logo: {
      //     src: require("@/assets/images/fssp-logo.png"),
      //     alt: "ФССП",
      //     width: 70,
      //   },
      //   bg: `center / cover url(${require("@/assets/images/guarantees-3.jpg")}), #000`,
      //   textColor: "#fff"
      // },
      {
        type: {
          name: "B2B",
          type: "b2b"
        },
        class: "deutsche-bank",
        title: "Deutsche Bank",
        description: `
          Методология оценки использования</br> 
          ресурсов проекта, подготовка модели</br> 
          изучения эффективности проектов,</br> 
          операционный менеджмент кадрового</br> 
          ресурса в РФ
        `,
        bg: `bottom 54px right 46px / 96px url(${require("@/assets/images/bank-bg.png")}), #0025A8`,
        textColor: "#fff"
      },
      {
        type: {
          name: "B2B",
          type: "b2b"
        },
        class: "sber",
        title: `
          Запуск «СПАСИБО </br>
          от Сбербанка»
        `,
        description: `
          Крупнейшая ПЛ в финансовом</br> секторе -> MAU 45 mln
        `,
        logo: {
          src: require("@/assets/images/sber-logo.png"),
          alt: "Сбер",
          width: 143
        },
        bg: `bottom right / auto 97% url(${require("@/assets/images/sber-bg.png")}), #05AB60`,
        textColor: "#fff"
      },
      // {
      //   type: {
      //     name: "B2G",
      //     type: "b2g"
      //   },
      //   class: "rzd",
      //   title: `
      //     Федеральное агентство</br> Ж/Д транспорта
      //   `,
      //   description: `
      //     Разработка электронной</br> энциклопедии подвижного</br> состава
      //   `,
      //   logo: {
      //     src: require("@/assets/images/rzd-logo.png"),
      //     alt: "РЖД",
      //     width: 104
      //   },
      //   bg: `bottom right / 74% url(${require("@/assets/images/rzd-bg.png")}), #B52B31`,
      //   textColor: "#fff"
      // },
      {
        type: {
          name: "B2B",
          type: "b2b"
        },
        class: "skillaz",
        title: "Skillaz",
        description: `
          Стартапменеджмент,</br> 
          упаковка для экспансии</br> 
          в СНГ и ЕС (проданы</br> 
          HH.ru за 1 млрд руб.)
        `,
        bg: `bottom 10px right 10px url(${require("@/assets/images/skillaz-bg.svg")}), #FFDE78`,
        textColor: "#000"
      },
      {
        type: {
          name: "Startup",
          type: "startup"
        },
        class: "beauty",
        title: "Beauty Maker",
        description: `
          <ul>
            <li>
              оценка инвесторов до запуска 30 млн ₽</br> (вход инвестора на 10%)
            </li>
            <li>
              старт продаж 27.07
            </li>
            <li>
              поддержка «Фонда содействия</br> инновациям» (3 млн руб.)
            </li>
          </ul>
        `,
        bg: `center left 574px / auto 100% url(${require("@/assets/images/beauty-bg.png")}), linear-gradient(91.84deg, #5454D4 0.26%, #874CFA 48.18%, #D680E6 95.11%), #828282`,
        textColor: "#fff"
      },

      // {
      //   type: {
      //     name: "B2B",
      //     type: "b2b"
      //   },
      //   class: "tinkoff",
      //   title: "Тинькофф банк",
      //   description: `
      //     Разработка продукта</br>
      //     по инфраструктуре</br>
      //     инвестиций (включая</br>
      //     скоринг-систему) -></br>
      //     за 1 год отработано</br>
      //     >70000 сделок в supply-chain</br>
      //     интерфейсе
      //   `,
      //   bg: `right bottom / auto 97% url(${require("@/assets/images/tinkoff.png")}), #FFDE00`,
      //   textColor: "#000"
      // },
      {
        type: {
          name: "B2G",
          type: "b2g"
        },
        class: "window",
        title: `
          Российский </br>
          экспортный центр
        `,
        description: "Портал «Одно окно»",
        bg: `bottom 73px right 46px / 56% url(${require("@/assets/images/one-window.png")}), #14355D`,
        textColor: "#fff"
      },
      // {
      //   type: {
      //     name: "B2G",
      //     type: "b2g"
      //   },
      //   class: "bdip",
      //   title: "БДИП ФССП России",
      //   description: "Разработка асинхронного API",
      //   logo: {
      //     src: require("@/assets/images/bdip-logo.png"),
      //     alt: "БДИП ФССП",
      //     width: 70
      //   },
      //   bg: `center / cover url(${require("@/assets/images/bdip-bg.jpg")}), #000`,
      //   textColor: "#fff"
      // },
      {
        type: {
          name: "B2B",
          type: "b2b"
        },
        class: "moskow",
        title: "Московская биржа",
        description: `
          Разработка сетевых решений для</br> 
          подключения к бирже (удаленные подключения</br> 
          через сети авторизованных операторов,</br> 
          глобальные финансовые сети, выделенные<br> 
          каналы "точка-точка"). Проект запущен за 6</br> 
          мес. (на 1 мес. раньше срока по договору)
        `,
        bg: `center / cover url(${require("@/assets/images/moskow-bg.jpg")}), #fff`,
        textColor: "#333"
      },
      {
        type: {
          name: "B2G",
          type: "b2g"
        },
        class: "bitrix",
        title: `
          Проекты для Национальной </br>
          системы аккредитации
        `,
        description: `
          Bitrix (разработка компонентов),</br> интеграции с подсистемами
        `,
        logo: {
          src: require("@/assets/images/bitrix-logo.png"),
          alt: "Росаккредитация",
          width: 166
        },
        bg: `center right 30px / 59% url(${require("@/assets/images/bitrix-bg.png")}), #D63459`,
        textColor: "#fff"
      },
      // {
      //   type: {
      //     name: "B2G",
      //     type: "b2g"
      //   },
      //   class: "fts",
      //   title: `
      //     Интеграционные </br>
      //     проекты для ФТС
      //   `,
      //   description: `
      //     Участие в разработке ЕАИС, тех. стек:</br>
      //     backend, api, views, Laravel, postgres,</br>
      //     Elasticsearch (search), JSVanilla, JQuery
      //   `,
      //   logo: {
      //     src: require("@/assets/images/fts-logo.png"),
      //     alt: "ФТС",
      //     width: 70
      //   },
      //   bg: `bottom right / cover url(${require("@/assets/images/fts-bg.png")}), #0F7A65`,
      //   textColor: "#fff"
      // },
      // {
      //   type: {
      //     name: "B2B",
      //     type: "b2b"
      //   },
      //   class: "wargaming",
      //   title: "Wargaming",
      //   description: `
      //     Позиционирование, адаптация продукта</br>
      //     для РФ, составление бизнес требований,</br>
      //     проработка сроков и оптимизация</br>
      //     методологии разработки
      //   `,
      //   logo: {
      //     src: require("@/assets/images/wargaming-logo.png"),
      //     alt: "Wargaming",
      //     width: 117
      //   },
      //   bg: `center / cover url(${require("@/assets/images/wargaming-bg.jpg")}), #000`,
      //   textColor: "#fff"
      // },
      {
        type: {
          name: "Startup",
          type: "startup"
        },
        class: "what-do-you-say",
        title: "“ЧТО СКАЖЕШЬ?”",
        description: `
          <ul>
            <li>
              проведён пилот с Nestle – </br> KPI перевыполнены
            </li>
            <li>
              вход инвестиционного фонда из Германии, оценка проекта на раунде А - 1 млн. евро
            </li>
            <li>
              тираж с 01.08
            </li>
            <li>
              поддержка «Фонда содействия инновациям»
            </li>
          </ul>
        `,
        // columns: 2,
        bg: `right / contain url(${require("@/assets/images/what-do-you-say-bg.png")}), center / cover url(${require("@/assets/images/what-do-you-say-bg-second.svg")}), #F8F8F8`,
        textColor: "#333"
      },
    ]
  },
}

const getters = {
  CASES: state => state.cases,
}

export default {
  state,
  getters,
}