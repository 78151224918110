<template>
  <header class="header">
      <div class="header__container container">
          <div class="header__logo-box">
            <img :src="logo" alt="Preon.Tech" width="180" class="header__logo">
          </div>

          <div class="header--mobile">
            <router-link :to="{ name: 'Request' }" class="header__button button">Запросить оценку</router-link>
          </div>

          <button class="header__burger" @click="toggleMenu"></button>
          
          <div :class="['header__box', menuIsOpen ? 'active' : '']">
            <navigation class="header__nav"></navigation>

            <div class="header--desktop">
              <router-link :to="{ name: 'Request' }" class="header__button button">Запросить оценку</router-link>
            </div>

            <button class="header__nav-close" @click="toggleMenu"></button>
          </div>
      </div>
  </header>
</template>

<script>
import Navigation from './Navigation.vue'
export default {
  components: { Navigation },
  name: "Header",

  data() {
      return {
          logo: require("@/assets/logo.png"),
          menuIsOpen: false,
      }
  },
  methods: {
    toggleMenu() {
      this.menuIsOpen = !this.menuIsOpen
    }
  }
}
</script>

<style lang="less">
  .header {
    padding: 40px 0;
    
    &--mobile {
      display: none;
    }

    &__container {
      display: flex;
      align-items: center;

      max-width: 1660px;
    }

    &__box {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    &__nav {
      flex-grow: 1;
      padding-right: 20px;

      & .nav {
        &__list {
          justify-content: flex-end;
        }
      }
    }

    &__button {
      width: 193px;
    }

    &__logo {
      display: block;
      width: 180px;
      &-box {
        width: 193px;
      }
    }

    &__nav-close {
      display: none;
    }

    &__burger {
      display: none;
    }
  }

  @media (max-width: 1030px) {
    .header {
      &__logo,
      &__logo-box,
      &__button {
        width: 150px;
      }
      &__button {
        width: 135px;
        font-size: 14px;
      }

      &__logo-box {
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 890px) {
    .header {
      padding: 26px 0;

      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
      
      &__box {
        position: fixed;
        z-index: 99;

        flex-direction: column;
        justify-content: center;

        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(100%);

        background-color: transparent;

        transition: transform, background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;

        &.active {
          transform: translateX(0);
          background-color: var(--color-gray-light);
        }
      }

      &__nav {
        flex-grow: 0;
        padding-right: 0;

        & .nav {
          &__list {
            flex-direction: column;
          }

          &__item {
            padding: 0 20px;

            &:not(:last-child) {
              padding-bottom: 20px;
            }
          }

          &__link {
            color: var(--color-gray);
            font-size: 30px;
            line-height: 120%;
            letter-spacing: -0.01em;

            padding-left: 43px;

            background-image: url("../assets/logo-small.svg");
            background-repeat: no-repeat;
            background-position: 0px 12px;
          }
        }
      }

      &__button {
        // width: 261px;
      }

      &__nav-close {
        display: block;
        border: none;
        padding: 0;
        background-color: transparent;
        position: absolute;
        z-index: 2;

        top: 22px;
        right: 22px;

        width: 30px;
        height: 30px;

        background-image: url("../assets/icons/close.svg");
        background-repeat: no-repeat;
        background-position: center;

        &:active {
          opacity: 0.2;
        }
      }

      &__burger {
        display: block;
        padding: 0;
        border: none;
        background-color: transparent;

        width: 36px;
        height: 36px;

        background-image: url("../assets/icons/burger.svg");
        background-repeat: no-repeat;
        background-position: center;

        margin-left: auto;
      }
    }
  }
</style>