<template>
  <div id="app">
    <router-view />
    <Footer :class="[currentRouteName == 'Request' ? 'footer--request' : '']"></Footer>
    <notifications group="main" />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Footer
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  }
}
</script>

<style lang="less">
  :root {
    --color-black: #000;
    --color-black-light: #080022;

    --color-clear-white: #fff;

    --color-gray: #333;
    --color-gray-medium: #BDBDBD; 
    --color-gray-light: #E5E5E5;

    --color-blue: #2A5EE9;
    --color-blue-light: #598BFF;
    --color-blue-dark: #0E003F;

    --color-green: #95E2BA;
    --color-yellow: #FFD68D;
    --color-orange: #FE8E4C;
    --color-blue-sky: #B5E8F7;
  }

  @font-face {
    font-family: 'Circe';
    src: url('assets/font/Circe-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circe';
    src: url('assets/font/Circe-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    font-family: 'Circe', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: var(--color-gray);

    background-color: var(--color-clear-white);

    overflow-x: hidden;
  }

  #app {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    flex-grow: 1;
  }

  .link {
    color: var(--color-blue);

    transition: color 0.2s;

    &:hover,
    &:focus {
      color: var(--color-blue-light)
    }
  }

  .visually-hidden {
    position: absolute;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px;
  }

  .main {
    flex-grow: 1;
  }

  button {
    cursor: pointer;
  }

  .container {

    width: 100%;
    max-width: 1180px;

    box-sizing: border-box;

    padding: 0 20px;
    margin: 0 auto;

    &--big {
      max-width: 1326px;
    }
  }

  .button {
    text-decoration: none;
    border: none;
    padding: 0;
    display: block;

    font-family: inherit;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.1;
    text-align: center;
    color: var(--color-clear-white);
    
    background: #2A5EE9;
    border-radius: 20px;
    padding: 13px 5px;
    padding-bottom: 12px;

    transition: background-color 0.2s;

    box-sizing: border-box;

    &:hover {
      background-color: var(--color-blue-light);
    }
  }

  .title {
    margin: 0;
    font-weight: bold;
    font-size: 50px;
    line-height: 110%;
    letter-spacing: -0.01em;

    margin-bottom: 70px;

    &--big {
      font-size: 70px;
    }

    @media (max-width: 890px) {
      font-size: 40px;
      margin-bottom: 40px;

      &--big {
        font-size: 40px;
      }
    }
  }

  .grid {
    display: grid;

    &--middle {
      align-items: center;
    }

    &--2-columns {
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
      grid-gap: 30px;

      @media (max-width: 629px) {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-gap: 16px;
      }
    }

    &--3-columns {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      grid-gap: 30px;

      @media (max-width: 629px) {
        grid-gap: 16px;
      }
    }
  }

  .bg {
    &-blue-dark {
      color: var(--color-clear-white);
      background-color: var(--color-blue-dark);
    }
  }
</style>
